<template>
  <div>
    <div class="block">
      <div class="block-banner">
        <video
          id="videos"
          muted="muted"
          class="block-banner"
          src="https://qncdn.uni-sheep.com/s/banner_1%2018.19.40.mp4"
          autoplay="autoplay"
          loop
        ></video>
        <button class="applice family-picturetext" @click="Information">
          申请体验
        </button>
      </div>
    </div>
    <!-- 完善的产品体系 -->
    <div class="perfect-box">
      <div class="perfect-centerbox" v-if="perfectdisplay">
        <div class="perfect-tibox">
          <div class="perfect-title family-title">完善的产品体系</div>
          <div class="perfect-xian"></div>
        </div>
        <div class="perfect-secondbox">
          <div class="perfect-lfbox">
            <div class="per-tcbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20245%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">历史数据导入</div>
            </div>
            <div class="per-tctbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20245%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">流程设计</div>
            </div>
            <div class="per-tcthbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20245%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">工单派发</div>
            </div>
            <div class="per-fbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20245%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">硬件对接</div>
            </div>
            <div class="per-fifbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20245%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">批量采集</div>
            </div>
            <div class="per-sbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20245%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">设备录入</div>
            </div>
          </div>
          <div class="perfect-zbox">
            <div class="perfec-topbox"></div>
            <div class="perfec-tuone"></div>
            <div class="perfec-tutwo" @click="Perfecsheep">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20726%281%29.png"
                alt=""
              />
            </div>
            <div class="perfec-botbox">
              <div class="perfec-boonebox">
                <div class="perfec-bolif">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Ellipse%20246%281%29.png"
                    alt=""
                  />
                  <div class="per-hi family-about">自定义分析</div>
                </div>
                <div class="perfec-bolifto">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Ellipse%20246%281%29.png"
                    alt=""
                  />
                  <div class="per-hi family-about">智能分析</div>
                </div>
              </div>
              <div class="perfec-botwobox">
                <div class="perfec-bolif">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Ellipse%20246%281%29.png"
                    alt=""
                  />
                  <div class="per-hi family-about">多功能驾驶舱</div>
                </div>
              </div>

              <div class="perfec-bothreebox">
                <div class="perfec-bothone">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Ellipse%20246%281%29.png"
                    alt=""
                  />
                  <div class="per-hi family-about">智能预警</div>
                </div>
                <div class="perfec-bothtwo">
                  <img
                    src="https://qncdn.uni-sheep.com/s/Ellipse%20246%281%29.png"
                    alt=""
                  />
                  <div class="per-hi family-about">分析报告</div>
                </div>
              </div>
            </div>
          </div>
          <div class="perfect-rfbox">
            <div class="perfect-ronbox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20250%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">咨询服务</div>
            </div>
            <div class="perfect-rtobox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20250%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">解决方案</div>
            </div>
            <div class="perfect-rthrobox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20250%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">生产评估</div>
            </div>
            <div class="perfect-rforobox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20250%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">效果比对</div>
            </div>
            <div class="perfect-rfivobox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20250%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">牧场预算</div>
            </div>
            <div class="perfect-rsixvobox">
              <img
                src="https://qncdn.uni-sheep.com/s/Ellipse%20250%281%29.png"
                alt=""
              />
              <div class="per-hi family-about">数据诊断</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 牧场存在的困扰 -->
    <div class="plaguebox">
      <div class="plaguebox-center" v-if="ranchdisplay">
        <div class="plague-tibox">
          <div class="plague-title family-title">牧场存在的困扰？</div>
          <div class="plague-xian"></div>
        </div>

        <div class="plague-imgbox">
          <div class="plaguefirstbox">
            <div class="plague-problem">
              <div class="plague-imgpro">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%2066%281%29.png"
                  alt=""
                />
              </div>
              <div class="plague-protitle">问题</div>
            </div>
            <div class="plague-con family-about">
              人员变动，牧场生产经营活动受到很大影响？
            </div>
            <div class="plague-con family-about">
              工作人员了解所有技术操作流程，但执行效果参差不齐？
            </div>
            <div class="plague-con family-about">
              牧场如何管控到每只牛羊的执行方案？
            </div>
          </div>
          <div class="plaguefirstbox">
            <div class="plague-problem">
              <div class="plague-imgpro">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%2066%281%29.png"
                  alt=""
                />
              </div>
              <div class="plague-protitle">问题</div>
            </div>
            <div class="plague-con family-about">
              如何看到每个牧场的实际经营生产情况？
            </div>
            <div class="plague-con family-about">
              牧场如何多角度分析生产管控指标？
            </div>
            <div class="plague-con family-about">
              多牧场如何建立高效的分析体系？
            </div>
            <div class="plague-con family-about">
              牧场如何与财务的数据双向传输？
            </div>
          </div>
          <div class="plaguefirstbox">
            <div class="plague-problem">
              <div class="plague-imgpro">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%2066%281%29.png"
                  alt=""
                />
              </div>
              <div class="plague-protitle">问题</div>
            </div>
            <div class="plague-con family-about">
              如何知道哪个方案更适合自己的牧场？
            </div>
            <div class="plague-con family-about">
              牧场如何制定未来3-5年的规划？
            </div>
            <div class="plague-con family-about">如何挖掘牧场的综合潜力？</div>
            <div class="plague-con family-about">
              牧场如何监管每只羊的执行方案？
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我能帮助你做什么? -->
    <div class="helpbox">
      <div class="help-conter" v-if="helpdisplay">
        <div class="help-contact">
          <div class="help-title family-title">我能帮助你做些什么？</div>
          <div class="help-xian"></div>
        </div>
        <div class="help-content">
          <div class="help-left">
            <div>
              <div class="help-top">
                <div class="help-xl">
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">清晰完整的生产数据</div>
                  </div>
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">搭建标准的业务流程</div>
                  </div>
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">明确每日的工作任务</div>
                  </div>
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">可查看的执行力</div>
                  </div>
                </div>
                <div class="help-xl">
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">
                      具有指导意义的数据分析报表
                    </div>
                  </div>
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">
                      软硬件数据互通/业务协同
                    </div>
                  </div>
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">牧场未来3-5年预算</div>
                  </div>
                  <div class="help-dbox">
                    <div class="help-yuan"></div>
                    <div class="help-text family-about">实现业财一体化</div>
                  </div>
                </div>
              </div>
              <div class="help-bottom">
                <button class="help-applice family-about" @click="Applyforuse">
                  申请使用 >
                </button>
              </div>
            </div>
          </div>
          <div class="help-right">
            <img
              src="https://qncdn.uni-sheep.com/s/%E7%94%B5%E8%84%91%281%29.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 数字大屏 -->
    <div class="large-box">
      <div class="large-screen">
        <div class="large-imbox">
          <div class="large-img family-title">可自定义数据驾驶舱</div>
          <div class="large-xian"></div>
        </div>
      </div>
      <div class="large-center">
        <el-carousel height="660px">
          <el-carousel-item v-for="item in largeBanners" :key="item.index">
            <img :src="item.imgUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 引领国内智慧牧业发展 -->
    <div class="leadbox" v-if="leaddisplay">
      <div class="lead-onebox">
        <div class="lead-bao">
          <div class="lead-onet family-title">引领国内智慧牧业发展</div>
        </div>
        <div class="lead-tit family-text">
          我们一直在前行，无论您身在何处，均能获得自牧天成本地化的优质服务
        </div>
      </div>
      <div class="lead-twobox">
        <div class="lead-dong" v-for="(item, index) in numberList" :key="index">
          <div class="lead-dtop">
            <div class="lead-timg">
              <img
                src="https://qncdn.uni-sheep.com/s/Vector%20%287%29%281%29.png"
                alt=""
              />
            </div>
            <div class="lead-nmb">
              <animate-number
                ref="valueNumber"
                from="0"
                :to="item.num"
                :key="item.num"
              ></animate-number
              ><span>{{ item.wan }}</span>
            </div>
            <div class="lead-jia">+</div>
          </div>
          <div class="lead-dbtom">{{ item.content }}</div>
        </div>
      </div>
      <div class="lead-twothree">
        <img
          src="https://qncdn.uni-sheep.com/s/Mask%20group%20%288%29%281%29.png"
          alt=""
        />
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="partners-box">
      <div class="partners-img">
        <div class="partners-tit family-title">
          <!-- <img src="../../../../public/imgs/ads/合作伙伴(1).png" alt="" /> -->
          他们选择了我们
        </div>
        <div class="partners-xian"></div>
      </div>

      <!-- /* 底部自动滚动动画样式完毕 */ -->
      <div class="lucky-user">
        <!-- 容器 -->
        <ul
          class="user-list"
          :style="`animation-duration: ${luckyUsers.length * 4}s;`"
        >
          <!-- 内容区容器 -->
          <li v-for="(item, index) in luckyUsers" :key="index">
            <img :src="item.imgUrl" alt="" />
          </li>
          <!-- 内容A -->
          <li v-for="(item, index) in luckyUsers" :key="index + 'copy'">
            <img :src="item.imgUrl" alt="" />
          </li>
          <!-- 内容B -->
        </ul>
        <ul
          class="user-listtwo"
          :style="`animation-duration: ${luckyUsersh.length * 4}s;`"
        >
          <!-- 内容区容器 -->
          <li v-for="(item, index) in luckyUsersh" :key="index">
            <img :src="item.imgUrl" alt="" />
          </li>
          <!-- 内容A -->
          <li v-for="(item, index) in luckyUsersh" :key="index + 'copy'">
            <img :src="item.imgUrl" alt="" />
          </li>
        </ul>

        <ul
          class="user-list"
          :style="`animation-duration: ${luckyUserthre.length * 4}s;`"
        >
          <!-- 内容区容器 -->
          <li v-for="(item, index) in luckyUserthre" :key="index">
            <img :src="item.imgUrl" alt="" />
          </li>
          <!-- 内容A -->
          <li v-for="(item, index) in luckyUserthre" :key="index + 'copy'">
            <img :src="item.imgUrl" alt="" />
          </li>
          <!-- 内容B -->
        </ul>
      </div>
      <!-- /* 底部自动滚动动画样式完毕 */ -->
    </div>

    <!-- 公共脚步部分 -->
    <public-footer @Immediately="Immediately"></public-footer>
  </div>
</template>
<script>
import PublicFooter from "../../../components/publicfooter/index.vue";
// import { getList } from "@/api/home";
// getPartnersData
export default {
  name: "home-page",
  components: {
    PublicFooter,
  },
  props: [],

  data() {
    return {
      // 数字大屏
      largeBanners: [
        // {
        //   imgUrl: require("../../../../public/imgs/ads/数字化驾驶舱 2(1).png"),
        // },
        // {
        //   imgUrl: require("../../../../public/imgs/ads/数字大屏.png"),
        // },
        {
          imgUrl:
            "https://qncdn.uni-sheep.com/s/%E5%A4%A7%E6%95%B0%E6%8D%AE%E9%A9%BE%E9%A9%B6%E8%88%B1%E6%BC%94%E7%A4%BA%201%402x.png",
        },
      ],
      // 合作伙伴区域A
      // luckyUsers: [],
      // 合作伙伴区域B
      // luckyUsersh: [],
      // 合作伙伴区域C
      // luckyUserthre: [],
      // 滚动高度
      scrollTop: "",
      //完善的产品体系动画显示
      perfectdisplay: false,
      // 牧场存在的困扰动画显示
      ranchdisplay: false,
      // 我能帮助你做什么动画显示
      helpdisplay: false,
      // // 数字大屏动画显示
      // largedisplay: false,
      //引领国内智慧牧业发展动画显示
      leaddisplay: false,
      // 合作伙伴动画显示
      cooperdisplay: false,
      numberList: [
        {
          content: "服务牛头数",
          num: "150",
          wan: "W",
        },
        {
          content: "服务羊只数",
          num: "15",
          wan: "W",
        },
        {
          content: "服务牧场数",
          num: "450",
        },
        {
          content: "覆盖省份自治区区域",
          num: "20",
        },
      ],
      luckyUsers: [
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20313%281%29.png",
        },
        {
          imgUrl:
            "https://qncdn.uni-sheep.com/s/Group%20314%20%282%29%281%29.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20315.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20316.png",
        },
        {
          imgUrl:"https://qncdn.uni-sheep.com/s/Group%20317.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20318.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20319.png",
        },
      ],
      luckyUsersh: [
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20320.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20321.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20322.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20323.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20324.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20325.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20326.png",
        },
      ],
      luckyUserthre: [
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20327.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20328.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20329.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20330.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20331.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%201028.png",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20333.png",
        },
      ],
    };
  },
  methods: {
    // 获取列表数据
    // async getList() {
    //   try {
    //     const res = await getList();
    //     console.log(res);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // 合作伙伴本地数据
    // getPartnersDatas() {
    //   getPartnersData()
    //     .then((data) => {
    //       console.log(data);
    //       const arr = ["luckyUsers", "luckyUsersh", "luckyUserthre"];
    //       for (const item of arr) {
    //         this[item] = data[item];
    //       }
    //     })
    //     .catch(() => {
    //       this.$message.error("获取本地数据失败");
    //     });
    // },
    // 申请体验
    Information() {
      this.Applyforuse();
    },
    // 申请使用
    Applyforuse() {
      this.$router.push("/fillinformation");
    },
    // 立即咨询
    Immediately() {
      this.$router.push("/fillinformation");
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // Unisheep生成管理平台跳转Unisheep羊只生成管理平台
    Perfecsheep() {
      this.$router.push("/product");
    },

    // 获取浏览器滚动条高度
    handleScroll(event) {
      // console.log("滚动条高度为：", event.target.scrollTop);
      this.scrollTop = event.target.scrollTop;
      const topObj = {
        100: "perfectdisplay",
        900: "ranchdisplay",
        1500: "helpdisplay",
        2800: "leaddisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
    handleScrolls(e) {
      if (
        parseInt(e.target.scrollTop) <= 2800 &&
        parseInt(e.target.scrollTop) <= 3000 &&
        this.numberList &&
        this.numberList.length > 0 &&
        this.$refs.valueNumber &&
        this.$refs.valueNumber.length > 0
      ) {
        this.numberList.forEach((item, index) => {
          this.$refs.valueNumber[index].start();
        });
      }
    },
  },
  created() {
    // this.getList();
    this.listenerFunction();
  },
  mounted() {
    // 合作伙伴本地数据
    // this.getPartnersDatas();
    window.addEventListener("scroll", this.handleScrolls, true); //监听滚动事件
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
};
</script>
<style scoped lang="scss">
// 公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
//全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
//播放按钮
video::-webkit-media-controls-play-button {
  display: none;
}
//进度条
video::-webkit-media-controls-timeline {
  display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
//所有控件
video::-webkit-media-controls-enclosure {
  display: none;
}

img {
  width: 100%;
  height: inherit;
}
/* 头部 */
.block {
  width: 100%;
}
.block-banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
.applice {
  width: 12rem;
  height: 3.5rem;
  border: 0.0625rem solid white;
  color: #3f70f4;
  border-radius: 2rem;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 62%;
  left: 10%;
  font-size: 1.2rem;
  background: white;
  color: #4668e3;
  font-weight: 800;
}
.applice:hover {
  opacity: 66;
  border: none;
  background-color: #c6d4fc;
}
/* 完善的产品体系 */
.perfect-box {
  width: 100%;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f9ff;
  .perfect-centerbox {
    width: 74.5rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slide-up 1.5s ease-in-out;
    animation-fill-mode: forwards;
    .perfect-tibox {
      width: 14.5rem;
      height: 3.5rem;
      margin-top: 3.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .perfect-title {
        font-weight: 800;
        font-size: 2rem;
      }
      .perfect-xian {
        width: 14rem;
        height: 0.3rem;
        background: #4668e3;
      }
    }
    .perfect-secondbox {
      width: 100%;
      height: 40.7rem;
      display: flex;
      justify-content: center;
      margin-top: 3.6rem;
      .perfect-lfbox {
        width: 18rem;
        height: 20rem;
        .per-tcbox {
          width: 100%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .per-tctbox {
          width: 60%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          margin-left: 5rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .per-tcthbox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          margin-left: 10rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .per-fbox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          margin-left: 2rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .per-fifbox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          margin-left: 7rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .per-sbox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          margin-left: 3rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
      .perfect-zbox {
        width: 25rem;
        height: 100%;
        position: relative;
        .perfec-topbox {
          width: 25rem;
          height: 25rem;
          background: url("https://qncdn.uni-sheep.com/s/Group%20725%281%29.png");
          background-repeat: no-repeat;
          background-size: cover;
          animation: turn 6s linear infinite;
          animation-fill-mode: forwards; /* 添加这一行 */
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .perfec-tuone {
          width: 23rem;
          height: 23rem;
          background: url("https://qncdn.uni-sheep.com/s/Group%20724%281%29.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 1rem;
          left: 1rem;
          animation: turn 8s linear infinite;
          animation-fill-mode: forwards; /* 添加这一行 */
        }
        .perfec-tutwo {
          position: absolute;
          width: 13rem;
          height: 13rem;
          top: 6rem;
          left: 6rem;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .perfec-tutwo:hover {
          background: rgba(0, 0, 255, 0.836);
          border-radius: 50%;
          box-shadow: 0 0 0.25rem rgba(0, 0, 255, 0.836);
        }
        .perfec-botbox {
          position: absolute;
          width: 100%;
          height: 12rem;
          .perfec-boonebox {
            width: 100%;
            height: 3.3rem;
            display: flex;
            .perfec-bolif {
              width: 50%;
              height: 3.3rem;
              display: flex;
              align-items: center;
              img {
                width: 2.0275rem;
                height: 2rem;
              }
              .per-hi {
                font-size: 1.2rem;
                margin-left: -1.2rem;
              }
              .per-hi:hover {
                font-size: 1.4rem;
                font-weight: 600;
                cursor: pointer;
              }
            }
            .perfec-bolifto {
              width: 50%;
              height: 3.3rem;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              img {
                width: 2.0275rem;
                height: 2rem;
              }
              .per-hi {
                font-size: 1.2rem;
                margin-left: -1.2rem;
              }
              .per-hi:hover {
                font-size: 1.4rem;
                font-weight: 600;
                cursor: pointer;
              }
            }
          }
          .perfec-botwobox {
            width: 100%;
            height: 3.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .perfec-bolif {
              width: 50%;
              height: 3.3rem;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 2.0275rem;
                height: 2rem;
              }
              .per-hi {
                font-size: 1.2rem;
                margin-left: -1.2rem;
              }
              .per-hi:hover {
                font-size: 1.4rem;
                font-weight: 600;
                cursor: pointer;
              }
            }
          }
          .perfec-bothreebox {
            width: 100%;
            height: 3.3rem;
            display: flex;
            .perfec-bothone {
              width: 50%;
              height: 3.3rem;
              display: flex;
              align-items: center;
              img {
                width: 2.0275rem;
                height: 2rem;
              }
              .per-hi {
                font-size: 1.2rem;
                margin-left: -1.2rem;
              }
              .per-hi:hover {
                font-size: 1.4rem;
                font-weight: 600;
                cursor: pointer;
              }
            }
            .perfec-bothtwo {
              width: 50%;
              height: 3.3rem;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: -0.5rem;
              img {
                width: 2.0275rem;
                height: 2rem;
              }
              .per-hi {
                font-size: 1.2rem;
                margin-left: -1.2rem;
              }
              .per-hi:hover {
                font-size: 1.4rem;
                font-weight: 600;
                cursor: pointer;
              }
            }
          }
        }
      }
      .perfect-rfbox {
        width: 18rem;
        height: 20rem;
        .perfect-ronbox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .perfect-ronbox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .perfect-rtobox {
          width: 80%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .perfect-rthrobox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: 4rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .perfect-rforobox {
          width: 90%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .perfect-rfivobox {
          width: 40%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: 6rem;
          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .perfect-rsixvobox {
          width: 95%;
          height: 3.3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 2.0275rem;
            height: 2rem;
          }
          .per-hi {
            font-size: 1.2rem;
            margin-left: -1.2rem;
          }
          .per-hi:hover {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// 牧场存在的困扰
.plaguebox {
  width: 100%;
  height: 34rem;
  background: #ffffff;
  margin: 0 auto;
}
.plaguebox-center {
  width: 74.5rem;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slide-up 1.5s ease-in-out;
  animation-fill-mode: forwards;
}
.plague-tibox {
  width: 17rem;
  height: 3.5rem;
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.plague-title {
  font-weight: 800;
  font-size: 2rem;
}
.plague-xian {
  width: 14.5rem;
  height: 0.3rem;
  background: #4668e3;
}

.plague-imgbox {
  width: 100%;
  height: 22rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.plaguefirstbox {
  width: 23rem;
  height: 100%;
  background: url("https://qncdn.uni-sheep.com/s/Group%20294%281%29.png") center
    center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}
.plague-problem {
  width: 100%;
  height: 2.45rem;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.plague-imgpro {
  width: 1.8rem;
  height: 1.7rem;
}
.plague-protitle {
  font-size: 1.3rem;
  font-weight: 800;
  margin-left: 0.6rem;
}
.plague-con {
  width: 100%;
  height: 2.45rem;
  font-size: 1rem;
  color: #666666;
  line-height: 1.3rem;
  font-weight: 600;
}
/* 我能帮助你做什么? */
.helpbox {
  width: 100%;
  height: 30rem;
  background: #f6f9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.help-conter {
  width: 82.5rem;
  height: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  animation: slide-up 1.5s ease-in-out;
  animation-fill-mode: forwards;
}
.help-contact {
  width: 20.35rem;
  height: 3.5rem;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .help-title {
    font-size: 2rem;
    font-weight: 800;
  }
}
.help-xian {
  width: 18.5rem;
  height: 0.3rem;
  background: #4668e3;
}
.help-content {
  width: 80.5%;
  height: 20rem;
  display: flex;
  justify-content: space-around;
}

.help-left {
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.help-right {
  width: 45%;
  height: 100%;
}
.help-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.help-xl {
  width: 48%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.help-applice {
  width: 8rem;
  height: 2.5rem;
  border: 0.125rem solid #4668e3;
  color: #4668e3;
  border-radius: 0.3rem;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}
.help-applice:hover {
  opacity: 66;
  border: none;
  background-color: rgb(135, 194, 239);
}
.help-dbox {
  display: flex;
  align-items: center;
}
.help-yuan {
  width: 0.6rem;
  height: 0.6rem;
  background: #4668e3;
  border-radius: 1rem;
}
.help-text {
  font-size: 1rem;
  font-weight: 800;
  margin-left: 0.5rem;
}
// 数字大屏
.large-box {
  width: 100%;
  height: 56rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  animation: slide-up 1.5s ease-in-out;
  animation-fill-mode: forwards;
  .large-screen {
    width: 18rem;
    height: 3.5rem;
    margin-top: 2.7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .large-imbox {
      width: 30rem;
      height: 3.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .large-img {
        font-size: 2rem;
        font-weight: 800;
      }
      .large-xian {
        width: 17rem;
        height: 0.3rem;
        background: #4668e3;
      }
    }
  }
  .large-center {
    width: 82.5rem;
    height: 80%;
  }
}
//引领国内智慧牧业发展
.leadbox {
  width: 100%;
  height: 38rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  animation: slide-up 1.5s ease-in-out;
  animation-fill-mode: forwards;
  .lead-onebox {
    width: 42rem;
    height: 4.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    .lead-bao {
      width: 26.2rem;
      height: 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .lead-onet {
        font-size: 2rem;
        font-weight: 800;
      }
      .lead-xian {
        width: 19.5rem;
        height: 0.3rem;
        background: #4668e3;
        margin-left: 0.3rem;
      }
    }
    .lead-tit {
      color: #5c5d5f;
      font-size: 1.3rem;
    }
  }
  .lead-twobox {
    width: 60rem;
    height: 5.5rem;
    margin: 0 auto;
    display: flex;
    .lead-dong {
      width: 15rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .lead-dtop {
        width: 100%;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .lead-timg {
          width: 1.75rem;
          height: 2.4375rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .lead-nmb {
          font-size: 2.2rem;
          color: #306fe6;
          margin-left: 0.5rem;
        }
        .lead-jia {
          font-size: 1.8rem;
          color: #306fe6;
        }
      }
      .lead-dbtom {
        color: #646465;
        font-size: 1.2rem;
        margin-left: 1.4rem;
      }
    }
  }
  .lead-twothree {
    width: 84rem;
    height: 20rem;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
// 合作伙伴
.partners-box {
  width: 100%;
  height: 36rem;
  background: #f6f9ff;
  display: flex;
  flex-direction: column;
  animation: slide-up 1.5s ease-in-out;
  animation-fill-mode: forwards;
}
.partners-img {
  width: 27rem;
  height: 3.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  .partners-tit {
    font-size: 2rem;
    font-weight: 800;
  }
}
.partners-xian {
  width: 13.65rem;
  height: 0.3rem;
  background: #4668e3;
}
/* 底部自动滚动动画样式 */
.lucky-user {
  width: 82.5rem;
  height: 26rem;
  margin: 0 auto;
  line-height: 1.875rem;
  overflow: hidden;
  margin-top: 2rem;
}
.user-list {
  margin: 0 auto;
  width: fit-content;
  white-space: nowrap;
  animation-name: seamless-scrolling;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.user-listtwo {
  width: fit-content;
  white-space: nowrap;
  animation-name: seamless-scrollingtwo;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
li {
  display: inline-block;
}
li img {
  width: 12.8rem;
  height: 8rem;
}
@keyframes seamless-scrolling {
  0% {
    transform: translateX(0rem);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes seamless-scrollingtwo {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0rem);
  }
}

/* 底部自动滚动动画样式完毕 */
</style>
